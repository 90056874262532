import React from 'react';

import Navigator from "./Common/nav";
import Footer from "./Common/footer";
import Routes from "./Routes";

import './App.scss';

function App() {

  return (
    <div className="App">
      <Navigator />
      <Routes />
      <Footer />
    </div>
  );
}

export default App;
