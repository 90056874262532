import React, { useEffect, useRef, useState } from 'react'

// Antd
import { message, Tabs } from 'antd';

// Carousel
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

// Reveal
import AOS from 'aos';
import 'aos/dist/aos.css';

import './style.scss'
import './slider.scss'
import './icon-slider.css'
import './terminal.scss'
import emailjs from '@emailjs/browser';
import { BankOutlined, LoadingOutlined, MailOutlined, PhoneOutlined, SendOutlined } from '@ant-design/icons';


const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 4,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
    },
    tablet: {
        breakpoint: { max: 1024, min: 770 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 770, min: 0 },
        items: 1,
    },
};

const iconresponsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 14,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1450 },
        items: 10,
    },
    desktopMini: {
        breakpoint: { max: 1450, min: 1024 },
        items: 8,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 6,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 4,
    },
};

// Ant tab items
const onChange = (key) => {
    console.log(key);
};
const items = [
    {
        key: '1',
        label: 'Mission',
        children: <p>At Silicon Surge, our mission is to empower businesses with innovative and reliable IT solutions that drive growth, agility, and competitive advantage.We are committed to delivering exceptional value to our clients through continuous innovation, unparalleled expertise, and unwavering dedication to customer success.</p>,
    },
    {
        key: '2',
        label: 'Vision',
        children: <p>Our aim is to become the top choice for companies in search of revolutionary IT solutions, establishing new benchmarks for innovation, service quality, and customer satisfaction.</p>,
    },
];

AOS.init();
AOS.init({
    disable: false,
    startEvent: 'DOMContentLoaded',
    initClassName: 'aos-init',
    animatedClassName: 'aos-animate',
    useClassNames: false,
    disableMutationObserver: false,
    debounceDelay: 50,
    throttleDelay: 99,

    offset: 120,
    delay: 0,
    duration: 800,
    easing: 'ease',
    once: false,
    mirror: false,
    anchorPlacement: 'top-bottom',
});

const SERVICE_ID = "service_e824x55";
const TEMPLATE_ID = "template_ndv9ohr";
const PUBLIC_KEY = "ITPMy-UOFum0VQQCs";

const dataSource = [
    {
        icon: <img
            src={require('./Assets/Icons/eaas.png')}
            alt='Services'
        />,
        title: 'EaaS (Engineering as a Services)',
        content: <p>On-shore, off-shore, or near shore engineering teams available for customized utilization based on scope following industry best practices, agile methodologies and modern engineering practices to deliver solutions efficiently.
            <br />
            Services range from supporting complex transformation programs including projects from concept to implementation, operations, and eventual disposal.
            <br />
            Expert resources IBM WebSphere DB2, APPConnect, APIConnect, Filenet, WDG, Red Hat Open Shift, IBM Security</p>,
        barColor: '#4E2A84'
    },
    {
        icon: <img
            src={require('./Assets/Icons/system_migarion.png')}
            alt='Services'
        />,
        title: 'Systems Migration',
        content: <p><strong>Legacy Systems Modernization:</strong> Assessment and modernization of legacy systems to improve scalability, security, and performance while leveraging emerging technologies.
            <br />
            <strong>Platform Upgrades:</strong> Smooth transition of operating systems, databases, and middleware to newer versions or alternative platforms, ensuring compatibility and minimal disruption.
            <br />
            <strong>Data Migration:</strong> Secure and efficient transfer of data between systems or platforms, with a focus on data integrity, consistency, and compliance.
        </p>,
        barColor: '#4E2A84'
    },
    {
        icon: <img
            src={require('./Assets/Icons/maintain.png')}
            alt='Services'
        />,
        title: 'Cloud Migration Services',
        content: <p>
            <strong>Assessment and Planning:</strong> Evaluate current infrastructure and apps for migration to AWS, Azure, or Google Cloud.
            <br />
            <strong>Migration Execution:</strong> Transition systems to cloud with
            minimal downtime.
            <br />
            <strong>Optimization and Cost Management:</strong> Monitor and optimize cloud resources for efficiency and cost savings.
        </p>,
        barColor: '#4E2A84'
    },
    {
        icon: <img
            src={require('./Assets/Icons/tech_conuslt.png')}
            alt='Services'
        />,
        title: 'Technical Consulting Services',
        content: <p>
            <strong>Architecture Design:</strong> Collaborative design and development of scalable, resilient, and secure IT architectures tailored to clients' specific business requirements and objectives.
            <br />
            <strong>Performance Tuning:</strong> Optimization of system performance through thorough analysis, tuning, and fine-tuning of hardware, software, and network components.
            <br />
            <storng>Security Enhancement:</storng> Implementation of robust security measures and protocols to safeguard clients' infrastructure, applications, and data against evolving cyber threats.
        </p>,
        barColor: '#4E2A84'
    },
    {
        icon: <img
            src={require('./Assets/Icons/maintain.png')}
            alt='Services'
        />,
        title: 'Third-Party Maintenance Services',
        content: <p>
            <strong>Hardware Maintenance:</strong> Comprehensive maintenance and support services for third-party hardware components and systems, including servers, storage, networking equipment, and peripherals.
            <br />
            <strong>Software Support:</strong> Expert assistance with troubleshooting, patching, and updating third-party software applications, operating systems, and middleware to ensure optimal performance and reliability.
            <br />
            <strong>End-of-Life Management:</strong> Strategic planning and execution of end-of-life (EOL) and end-of-service-life (EOSL) processes for third-party hardware and software, including migration or decommissioning strategies.
        </p>,
        barColor: '#4E2A84'
    },
    {
        icon: <img
            src={require('./Assets/Icons/training.png')}
            alt='Services'
        />,
        title: 'Training and Enablement',
        content: <p>
            <strong>Technical Training:</strong> Customized training programs and workshops designed to empower clients' IT teams with the knowledge and skills required to effectively manage and optimize their IT infrastructure.
            <br />
            <strong>Certification Programs:</strong> Preparation and guidance for industry-standard certifications relevant to cloud computing, systems integration, and emerging technologies, enhancing clients' technical expertise and market competitiveness.
        </p>,
        barColor: '#4E2A84'
    },
    {
        icon: <img
            src={require('./Assets/Icons/digital-services.png')}
            alt='Services'
        />,
        title: 'Managed Services',
        content: <p>
            <strong>Proactive Monitoring and Management:</strong> 24/7 monitoring and management of clients' IT environments to identify and address potential issues before they escalate,ensuring maximum uptime and reliability.
            <br />
            <strong>Incident Response and Resolution:</strong> Rapid response to IT
            incidents and emergencies, with proactive problem
            resolution to minimize impact on clients' operations and
            mitigate risks.
        </p>,
        barColor: '#4E2A84'
    },];

const Home = () => {
    const [api, contextHolder] = message.useMessage();
    const [apiLoading, contextHolderLoading] = message.useMessage();
    const [loading, setLoading] = useState(false);
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        setLoading(true)
        sending()
        emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, PUBLIC_KEY)
            .then((result) => {
                success()
                console.log('result', result)
                setLoading(false)
                apiLoading.destroy()
                form.current.reset();
            }, (error) => {
                console.log('error', error)
                errorMail()
                setLoading(false)
            });
    };

    const sending = () => {
        apiLoading.open({
            type: 'loading',
            content: 'Sending Email...',
            duration: 0,
            className: 'custom-message',
        });
    };

    const success = () => {
        api.open({
            type: 'success',
            className: 'custom-message',
            content: 'Email Sent Successfully, I will get back to you soon...',
        });
        setTimeout(api.destroy, 5000);
    };

    const errorMail = () => {
        api.open({
            type: 'error',
            className: 'custom-message',
            content: 'Failed to send email, Please try again later',
        });
    };


    // Active link
    useEffect(() => {
        const activeTab = document.querySelector(".activenav");
        if (activeTab) {
            activeTab.classList.remove("activenav");
        }
        const currentTab = document.getElementById("navhome");
        if (currentTab) {
            currentTab.classList.add("activenav");
        }
    }, []);

    return (
        <div className='home-page'>
            {contextHolder}
            {contextHolderLoading}

            {/* 
                /// Hero Section
                ______________________________________________////
                ///
             */}
            <div className='hero' id='hero'>
                <h1 data-aos="fade-up">
                    Silicon Surge <span>(Pvt) Ltd.</span>
                </h1>
                <h4 data-aos="fade-up">
                    Pioneering Innovation, Shaping the Future. Explore cutting-edge solutions that illuminate tomorrow's technology landscape.
                </h4>
                <div className='hero-buttons'>
                    <a href='/#services' data-aos="fade-up"> Services </a>
                    <a className='border-btn' href='/#contact-us' data-aos="fade-up"> Contact Us </a>
                </div>
            </div>

            {
                /* 
                    /// About Section
                    ______________________________________________////
                    ///
                */
            }
            <div className='about' id='about-us'>
                <div className='about-left'>
                    <h3 className='title-top' data-aos="fade-up">About Silicon Surge</h3>
                    <h2 data-aos="fade-up" className='title'>Empowering Your Digital Future</h2>

                    <p className='para' data-aos="fade-up">
                        Silicon Surge is a vibrant and inventive IT services firm established by
                        three experienced industry experts with a combined 35 years of
                        experience at IBM. Specializing in offering customized IT solutions to
                        adapt to the changing requirements of businesses in diverse sectors,
                        our company concentrates on managed services, software,
                        networking, and telecommunications (Telco). Committed to
                        delivering innovative technology solutions that stimulate business
                        development and change, Silicon Surge is at the forefront.
                    </p>
                    <Tabs data-aos="fade-up" defaultActiveKey="1" items={items} onChange={onChange} className='tab' />
                </div>
                <div className='about-right' data-aos="zoom-in">
                    <img src={require('./Assets/services.webp')} alt='Services' />
                </div>
            </div>

            {/* 
            /// Services Section
            ______________________________________________////
            ///
            */}
            <div className='services' id='services'>
                <div className='services-inner' data-aos="fade-up">
                    <h3 className='title-top'>What We Do?</h3>
                    <h2 className='title'>
                        <span>Scope of</span> Services
                    </h2>
                    <p className='para' data-aos="fade-up">
                        We provide a range of comprehensive IT services
                        while ensuring excellent service delivery by our
                        experts.
                    </p>
                </div>

                <div className='section-five-cards' data-aos="zoom-in">
                    <div className="Slider">
                        <Carousel
                            responsive={responsive}
                            infinite={true}
                            autoPlay={true}
                            showDots={true}
                            autoPlaySpeed={3000}
                            containerClass="carousel-container"
                            removeArrowOnDeviceType={[
                                "tablet",
                                "mobile",
                                "desktop",
                                "superLargeDesktop",
                                "desktopMini",
                            ]}
                            dotListClass="custom-dot-list-style"
                            activeDotClass="custom-dot-active"
                        >
                            {
                                dataSource.map((data, index) => {
                                    return (
                                        <div className="card" key={index}>
                                            <div className='card-icon'>
                                                {data.icon}
                                            </div>
                                            <h1>{data.title}</h1>
                                            <div className='card-content'>
                                                {data.content}
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </Carousel>
                    </div>
                </div>
            </div>

            {/* 
            /// Technologies Section
            ______________________________________________////
            ///
            */}
            <div className='hero-technologies'>
                <div className='hero-technologies-up'>
                    <h3 className='title-top'>We are With</h3>
                    <h2 className='title'>
                        Latest Technologies
                    </h2>
                </div>
                <div className='hero-technologies-bottum'>
                    <div className="icon-slider" >
                        <Carousel
                            responsive={iconresponsive}
                            infinite={true}
                            autoPlay={true}
                            showDots={false}
                            autoPlaySpeed={3000}
                            containerClass="carousel-container"
                            removeArrowOnDeviceType={[
                                "tablet",
                                "mobile",
                                "desktop",
                                "superLargeDesktop",
                                "desktopMini",
                            ]}
                            dotListClass="custom-dot-list-style"
                            activeDotClass="custom-dot-active"
                        >

                            <div className="card">
                                <img
                                    src={require('./Assets/technologies/1.png')}
                                    alt='1'
                                />
                            </div>
                            <div className="card">
                                <img
                                    src={require('./Assets/technologies/2.png')}
                                    alt='1'
                                />
                            </div>
                            <div className="card">
                                <img
                                    src={require('./Assets/technologies/3.png')}
                                    alt='1'
                                />
                            </div>
                            <div className="card">
                                <img
                                    src={require('./Assets/technologies/4.png')}
                                    alt='1'
                                />
                            </div>
                            <div className="card">
                                <img
                                    src={require('./Assets/technologies/5.png')}
                                    alt='1'
                                />
                            </div>
                            <div className="card">
                                <img
                                    src={require('./Assets/technologies/6.png')}
                                    alt='1'
                                />
                            </div>
                            <div className="card">
                                <img
                                    src={require('./Assets/technologies/7.png')}
                                    alt='1'
                                />
                            </div>
                            <div className="card">
                                <img
                                    src={require('./Assets/technologies/8.png')}
                                    alt='1'
                                />
                            </div>
                            <div className="card">
                                <img
                                    src={require('./Assets/technologies/9.png')}
                                    alt='1'
                                />
                            </div>
                            <div className="card">
                                <img
                                    src={require('./Assets/technologies/10.png')}
                                    alt='1'
                                />
                            </div>
                            <div className="card">
                                <img
                                    src={require('./Assets/technologies/11.png')}
                                    alt='1'
                                />
                            </div>
                            <div className="card">
                                <img
                                    src={require('./Assets/technologies/12.png')}
                                    alt='1'
                                />
                            </div>
                            <div className="card">
                                <img
                                    src={require('./Assets/technologies/13.png')}
                                    alt='1'
                                />
                            </div>
                            <div className="card">
                                <img
                                    src={require('./Assets/technologies/14.png')}
                                    alt='1'
                                />
                            </div>
                            <div className="card">
                                <img
                                    src={require('./Assets/technologies/15.png')}
                                    alt='1'
                                />
                            </div>
                            <div className="card">
                                <img
                                    src={require('./Assets/technologies/16.png')}
                                    alt='1'
                                />
                            </div>
                            <div className="card">
                                <img
                                    src={require('./Assets/technologies/17.png')}
                                    alt='1'
                                />
                            </div>
                            <div className="card">
                                <img
                                    src={require('./Assets/technologies/18.png')}
                                    alt='1'
                                />
                            </div>
                            <div className="card">
                                <img
                                    src={require('./Assets/technologies/19.png')}
                                    alt='1'
                                />
                            </div>
                            <div className="card">
                                <img
                                    src={require('./Assets/technologies/20.png')}
                                    alt='1'
                                />
                            </div>
                            <div className="card">
                                <img
                                    src={require('./Assets/technologies/21.png')}
                                    alt='1'
                                />
                            </div>
                        </Carousel>
                    </div>
                </div>
            </div>


            {/* 
            /// Team Section
            ______________________________________________////
            ///
            */}

            <div className='home-team' id='team'>
                <div className='home-team-left'>
                    <h3 className='title-top' data-aos="fade-up">
                        Our Team
                    </h3>
                    <h2 className='title' data-aos="fade-up">
                        The Minds Behind Silicon Surge
                    </h2>
                    <p className='para' data-aos="fade-up">
                        Our team consist of distinguished individuals with strong expertise and in-depth experience in the global IT companies. Supported by a team of excellent engineers, our leadership has a combined experience of 35+ years in the industry.
                    </p>
                    <div className='home-team-cards' data-aos="zoom-in">
                        <div class="card card1">
                            <div class="details_1">
                                <div class="cardHeader">
                                    <h2 class="cardTitle">
                                        Gimhana Perera
                                    </h2>
                                    <h4 class="cardSubtitle">
                                        Founding Partner
                                    </h4>
                                </div>
                                <div class="cardText">
                                    Gimhana comes with a rich background in managed services and project management, bringing expertise in delivering scalable and robust solutions to clients’ needs worldwide.
                                </div>
                            </div>
                        </div>

                        <div class="card card2">
                            <div class="details_2">
                                <div class="cardHeader">
                                    <h2 class="cardTitle">
                                        Sharanka Perera
                                    </h2>
                                    <h4 class="cardSubtitle">
                                        Founding Partner
                                    </h4>
                                </div>
                                <div class="cardText">
                                    Sharanka has a strong background in Software Engineering, ICT Infrastructure Systems Design and Deployment as well as over 15 years of techno-commercial experience working with most leading enterprises in Sri Lanka.
                                </div>
                            </div>
                        </div>

                        <div class="card card3">
                            <div class="details_3">
                                <div class="cardHeader">
                                    <h2 class="cardTitle">
                                        Aruna Perera
                                    </h2>
                                    <h4 class="cardSubtitle">
                                        Founding Partner
                                    </h4>
                                </div>
                                <div class="cardText">
                                    Aruna is an experienced navigator in technology commercial management with over two decades of experience in IBM and Microsoft.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* 
            /// Contact Section
            ______________________________________________////
            ///
            */}
            <div className="getintouch" id='contact-us'>
                <h1 className='main-title' data-aos="zoom-in">
                    <span className='title'>Get in touch</span><span className='line'></span>
                </h1>

                <div className='getintouch-container'>
                    <div className='left' data-aos="fade-right">
                        <p className='para'>
                            Have questions or need more information? We're here to help. Reach out to us, and our team will get back to you promptly to assist with your inquiries or partnership opportunities.
                        </p>
                        <div className='connect' data-aos="fade-up">
                            <div
                                className='connect-item'
                            >
                                <p>
                                    <BankOutlined /> Silicon Surge - Sri Lanka
                                </p>

                                <div className='links'>
                                    <a href='tel:+94740629690'>
                                        <PhoneOutlined /> +94740629690
                                    </a>
                                    <a href='tel:+94777006018'>
                                        <PhoneOutlined /> +94777006018
                                    </a>
                                    <a href='mailto:silicon.sales@silicon-surge.com'>
                                        <MailOutlined /> silicon.sales@silicon-surge.com
                                    </a>
                                </div>
                            </div>
                            <div
                                className='connect-item'
                            >
                                <p>
                                    <BankOutlined /> PT Silikon Internasional Solusi - Indonesia
                                </p>
                                <div className='links'>
                                    <a href='/#'>
                                        <MailOutlined /> Ruko Mitra Matraman Blok B No. 1
                                        Jln. Matraman Raya No. 148
                                        East Jakarta
                                        Indonesia</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='right' data-aos="fade-left">
                        <form ref={form} onSubmit={sendEmail}>
                            <label>Name</label>
                            <input required type="text" name="user_name" />
                            <label>Email</label>
                            <input required type="email" name="user_email" />
                            <label>Message</label>
                            <textarea required name="message" />
                            <button type="submit">
                                {!loading ? <>
                                    Send
                                    <SendOutlined />
                                </>
                                    :
                                    <>
                                        Please Wait
                                        <LoadingOutlined />
                                    </>
                                }
                            </button>
                        </form>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Home